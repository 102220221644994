import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {Reservee} from '@wix/ambassador-table-reservations-v1-reservation/types'
import {Checkout as EcomCheckoutService} from '@wix/ambassador-checkout/http'
import {Checkout as ICheckout} from '@wix/ambassador-checkout/types'
import {EpOrdersProxy} from '@wix/ambassador-ep-orders-proxy/http'

import {APP_DEF_ID, ECOM_BASE_URL, CREATE_CHECKOUT_ENDPOINT} from '../utils/constants'

const getAuthorization = (flowAPI: ControllerFlowAPI) => {
  const token = flowAPI.controllerConfig.wixCodeApi.site.getAppToken?.(APP_DEF_ID)
  const instance = flowAPI.controllerConfig.appParams.instance

  return token ? token : instance
}

export const createCheckout = async (
  flowAPI: ControllerFlowAPI,
  reservationId: string,
  reservee: Reservee,
): Promise<{checkout?: ICheckout}> => {
  const {data} = await flowAPI.httpClient.post<{checkout?: ICheckout}>(CREATE_CHECKOUT_ENDPOINT, {
    reservationId,
    reservee: {
      firstName: reservee.firstName,
      lastName: reservee.lastName,
      phone: reservee.phone,
      email: reservee.email,
      contactId: reservee.contactId,
    },
  })

  return data
}

export const createOrderFromCheckout = async (flowAPI: ControllerFlowAPI, checkoutId: string) => {
  const authorization = getAuthorization(flowAPI)
  const checkoutService = EcomCheckoutService(ECOM_BASE_URL).CheckoutService()({authorization})

  return checkoutService.createOrder({id: checkoutId})
}

export const getOrderDetails = async (flowAPI: ControllerFlowAPI, orderId: string) => {
  const authorization = getAuthorization(flowAPI)
  const orderService = EpOrdersProxy(ECOM_BASE_URL).Orders()({authorization})

  const {order} = await orderService.getOrder({id: orderId})

  return order
}

export const checkoutService = {
  createCheckout,
  createOrderFromCheckout,
  getOrderDetails,
}
