import {SettingsParamsType} from '../../types/createStylesParams'

import {textsSettingsParams, TextsSettingsParams} from './Settings/Tabs/Texts/settingsParams'
import {layoutSettingsParams, LayoutSettingsParams} from './Settings/Tabs/Layout/settingsParams'

export type SettingsParams = TextsSettingsParams & LayoutSettingsParams

export const settingsParams: SettingsParamsType<SettingsParams> = {
  ...textsSettingsParams,
  ...layoutSettingsParams,
}

export default settingsParams
