import React, {FC, useEffect, useState} from 'react'
import {WixAtlasServiceWeb} from '@wix/ambassador-wix-atlas-service-web/http'

import {classes, st} from './Map.st.css'

interface Props {
  address: string
  className?: string
}

async function getUrl(address: string) {
  const {getEmbedMap} = WixAtlasServiceWeb('/api/wix-atlas-service-web').MapsWidgetServiceV0()()
  const {url} = await getEmbedMap({query: encodeURIComponent(address)})
  return url ?? ''
}

export const Map: FC<Props> = (props) => {
  const [src, setSrc] = useState('')

  useEffect(() => {
    getUrl(props.address).then(setSrc)
  }, [props.address])

  if (!props.address) {
    return null
  }

  return (
    <div className={st(classes.root, {}, props.className)}>
      {src && <iframe title={props.address} src={src} width="100%" height="100%" tabIndex={-1} />}
    </div>
  )
}
