import {createStylesParams} from '@wix/tpa-settings'

import {designStyleParams, DesignStylesParams} from './Settings/Tabs/Design/stylesParams'
import {layoutStyleParams, LayoutStylesParams} from './Settings/Tabs/Layout/stylesParams'

type StylesParams = DesignStylesParams & LayoutStylesParams

export default createStylesParams<StylesParams>({
  ...designStyleParams,
  ...layoutStyleParams,
})
