import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'

import {ErrorModal} from '../../../../components-shared/ErrorModal'

import {useHooks} from './useHooks'

export const ConfirmationErrorModal: React.FC = () => {
  const {t} = useTranslation()
  const {isModalOpen, onClose, onCtaButtonClick} = useHooks()
  const title = t('uou-reservations.reservation-confirmation.error-modal-title')
  const text = t('uou-reservations.reservation-confirmation.error-modal-text')
  const ctaButton = t('uou-reservations.reservation-confirmation.error-modal-cta')

  return (
    <ErrorModal
      isModalOpen={isModalOpen}
      title={title}
      text={text}
      ctaButton={ctaButton}
      onClose={onClose}
      onCtaButtonClick={onCtaButtonClick}
    />
  )
}
