import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings'

import {TextAlignment} from '../../../../../types/createStylesParams'

export type LayoutSettingsParams = {
  textAlignment: SettingsParamType.String
}

export const layoutSettingsParams = createSettingsParams<LayoutSettingsParams>({
  textAlignment: {
    type: SettingsParamType.String,
    inheritDesktop: false,
    getDefaultValue: () => TextAlignment.Center,
  },
})
