import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings'

export type TextsSettingsParams = {
  titleReservedText: SettingsParamType.Text
  titleRequestedText: SettingsParamType.Text
}

export const textsSettingsParams = createSettingsParams<TextsSettingsParams>({
  titleReservedText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('uou-reservations.reservation-confirmation.title'),
  },
  titleRequestedText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('uou-reservations.reservation-confirmation.requested-title'),
  },
})
