import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Button, Text, TextTypography} from 'wix-ui-tpa/cssVars'

import {Modal} from '../Modal'

import {classes, st} from './styles.st.css'

interface ErrorModalProps {
  title: string
  text: string
  ctaButton: string
  isModalOpen: boolean
  onClose: () => void
  onCtaButtonClick: () => void
}

export const ErrorModal: React.FC<ErrorModalProps> = ({
  title,
  text,
  ctaButton,
  isModalOpen,
  onClose,
  onCtaButtonClick,
}) => {
  const {t} = useTranslation()

  return (
    <Modal className={st(classes.root)} isOpen={isModalOpen} onClose={onClose}>
      <Text tagName="H2" typography={TextTypography.smallTitle} className={classes.title}>
        {t(title)}
      </Text>
      <Text className={classes.text}>{t(text)}</Text>
      <Button onClick={onCtaButtonClick} className={classes.ctaButton}>
        {t(ctaButton)}
      </Button>
    </Modal>
  )
}
