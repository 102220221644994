import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'
import {useCurrencyFormatter} from '@wix/yoshi-flow-editor'

export const usePaymentRuleData = (
  reservationLocation?: ReservationLocation,
  partySize?: number,
) => {
  const formatCurrency = useCurrencyFormatter()

  const locationPaymentRule = reservationLocation?.configuration?.reservationPayment

  const isPaymentRuleActive =
    locationPaymentRule?.minPartySize && partySize && partySize >= locationPaymentRule?.minPartySize
  const paymentRuleFeePerGuest = Number(locationPaymentRule?.value) || 0

  const currency = reservationLocation?.currency || ''

  const feePerGuestWithCurrency = currency
    ? formatCurrency({value: paymentRuleFeePerGuest, currency})
    : String(paymentRuleFeePerGuest)

  return {
    isPaymentRuleActive,
    paymentRuleFeePerGuest,
    feePerGuestWithCurrency,
    currency,
  }
}
