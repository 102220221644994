import React, {FC, ReactNode} from 'react'
import {Dialog, DialogProps} from 'wix-ui-tpa/cssVars'

import {classes} from './styles.st.css'

type ModalProps = {
  children: ReactNode
} & DialogProps

export const Modal: FC<ModalProps> = ({children, ...props}) => {
  return (
    <Dialog {...props} childrenWrapperClassName={classes.contentWrapper} wiredToSiteColors={false}>
      <div className={classes.content}>{children}</div>
    </Dialog>
  )
}
