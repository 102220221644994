import {StyleParamType, createStylesParams} from '@wix/tpa-settings'

export type LayoutStylesParams = {
  horizontalPadding: StyleParamType.Number
  verticalPadding: StyleParamType.Number
}

export const layoutStyleParams = createStylesParams<LayoutStylesParams>({
  horizontalPadding: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: (params) => (params.isMobile ? 20 : 40),
  },
  verticalPadding: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: (params) => (params.isMobile ? 20 : 40),
  },
})
