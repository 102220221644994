import React, {useEffect} from 'react'

import {useReservationConfirmationStorage} from '../../storage'
import {RequestStatus} from '../../../../utils/wrapRequest'

export const useHooks = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const {reservation, getReservationLocationsStatus, getReservationStatus, goToNewReservation} =
    useReservationConfirmationStorage()

  useEffect(() => {
    if (
      getReservationLocationsStatus === RequestStatus.FAILED ||
      getReservationStatus === RequestStatus.FAILED
    ) {
      setIsModalOpen(true)
    }
  }, [getReservationLocationsStatus, getReservationStatus])

  const onClose = () => {
    setIsModalOpen(false)
  }

  const onCtaButtonClick = () => {
    goToNewReservation(reservation)
  }

  return {isModalOpen, onClose, onCtaButtonClick}
}
